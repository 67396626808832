<template>
	<PreOrderPickupAtStoreForm
		is-edit
		:pre-order-id="$route.params.id"
		:pre-order-pickup-id="$route.params.pickupId"
	/>
</template>

<script>
import PreOrderPickupAtStoreForm from '@/components/PreOrderPickupAtStoreForm.vue';

export default {
	name: 'PreOrderPickupAtStoreEdit',
	components: {
		PreOrderPickupAtStoreForm,
	},
};
</script>
